<template>
  <div class="apparea app-deal-home">
    <div>
      <div class="home-background m-auto" >
        <img :src="imgBanner" class="banner" />
      </div>
      <button class="btn-close" @click="gotoDealFiter" v-if="isShowWelcome">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
    <div style="position:absolute;top:0px;height:0px"><h1 style="font-size: 0px">בוא נעוף - דילים מטורפים בכל יום</h1></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AppDealHome',
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      bannerHome: 'GET_APP_DEAL_BANNER',
    }),
    imgBanner() {
      return this.bannerHome(this.device);
    }
  },
  data () {
    return {
      isShowWelcome: false,
    };
  },
  created() {
    this.$store.dispatch('FETCH_APP_DEAL_BANNER');
    this.$store.commit('SET_CURRENT_PAGE', 'home');
  },
  mounted() {
    if(!this.isShowWelcome) {
      this.gotoDealFiter();
    }
  },
  methods: {
    gotoDealFiter() {
      const type = this.device === 'desktop' ? 'app-desktop' : 'app';
      this.$router.push({ path: `/deal-filter/${type}?channel=App` });
    },
  },
};
</script>

<style lang="less" scoped>
  .bonauf-desktop .apparea {
    max-width: 100% !important;
    background: transparent;
  }

  .btn-close {
    i {
      text-shadow: 1px 1px 2px rgba(0,0,0,1);
      color: #fff;
    }
  }

  .bonauf-desktop {
    .btn-close {
      i {
        font-size: 70px;
      }
    }
  }
</style>

